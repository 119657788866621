$cdnURL: "https://rest-filestore.whiteclover.uk/files/whiteclover.io";
.cardStacking {
  margin-top: 40px;
  padding: 0 72px;

  @media screen and (max-width: 1500px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 1200px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 24px;
  }
}

.wrapper {
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
  }
}

.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  height: 85vh;
  top: 100px;
  position: sticky;
  padding: 56px;
  border: 1px solid #63435B;
  border-bottom: 0;
  border-radius: 32px 32px 0 0 ;

  background-color: white;
  color: #333;

  @media screen and (max-width: 1500px) {
    padding: 32px;
  }

  @media screen and (max-width: 600px) {
    padding: 24px;
  }


  &VideoAndContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    p {
      width: 40%;
      text-align: justify;

      @media screen and (min-width: 1200px) {
        align-self: flex-end;
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    @media screen and (min-width: 1200px) and (max-height: 700px) {
      height: 80%;
    }
    @media screen and (min-width: 1200px) {
      flex-direction: row-reverse;
    }
    @media screen and (min-width: 765px) {
      //padding-bottom: 40px;
    }
  }

  &VideoWithFrame {
    width: auto;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 765px) {
      display: flex;
      justify-content: center;
    }

    @media screen and (min-width: 1200px) {
      width: 50%;
      justify-content: flex-end;
    }
    @media screen and (min-width: 1200px) and (max-height: 700px) {
      width: 39%;
    }
  }

  .weddingPlanningPhoto {
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px
  }
  h4 {
    //width: 124px;
    text-align: right;

    @media screen and (min-width: 1200px) {
      width: 80%;
    }
  }
  .videoFrame {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 76.25%;
    background-image: url($cdnURL + '/newassets/mobile_videoframe1.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 2.4% 2% 2.4% 2.6%;
    webkit-border-radius: 6%;
    -moz-border-radius: 6%;
    border-radius: 6%;
  }
}

@for $i from 3 through 5 {
  @media screen and (min-height: 700px) {
    .card:nth-child(#{$i}n) {
      top: ($i) * 45px;
    }
  }
}
